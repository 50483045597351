.videoPage {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.videoPage__card {
  border-radius: 10px;
  position: relative;
}

@media (min-width: 700px) {
  .videoPage {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1100px) {
  .videoPage {
    grid-template-columns: repeat(3, 1fr);
  }
}

.video__user {
  position: absolute;
  top: 10px;
  left: 10px;
}

.video_btns {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-right: 15px;
}

.btn {
  margin-top: 5px;
  //   margin-right: 15px;
}

.vjs-icon-placeholder.vjs-icon-placeholder {
  color: #fff;
}

.videoPage__pagination {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
