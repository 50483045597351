@import "./config/vars";
@import "./layout/SideBar";
@import "./layout/Header";
@import "./modules/profile";
@import "./components/Video.scss";
@import "./pages/login";
@import "./pages/error";

// pages

@import "./pages/videos";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

.full {
  width: 100vh !important;
  height: 100vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Myspin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;

  .Modale__content {
    background-color: white;
    padding: 3em;
    z-index: 1001;
    border-radius: 2px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.myCard__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 30px;
  & > * {
    margin-bottom: 0;
  }
  &:nth-child(even) {
    background-color: #f9f9f9;
    border-radius: 4px;
  }
}
.myCard__title {
  font-weight: 600;
  font-size: 19px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}
.ant-table-thead > * {
  text-transform: capitalize !important;
}

.loadingSpinner {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #134e5e4d;
}

a,
li,
span {
  color: $txt;
}

.Student__area {
  max-width: 100vw !important;
  max-height: 100vh !important;
  overflow: hidden;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  height: 100vh !important;
}

.content {
  margin-left: $sideBarSize;
  margin-top: 50px !important;
  height: calc(100vh - 50px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.component {
  border-radius: 4px;
  background-color: $bg;
  background-color: white;
}
.component__spacing {
  padding: 3em;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.img__rounded {
  border-radius: 50%;
}

.content {
  padding: 20px;
  background-color: $bg;
}
.myShadow {
  box-shadow: $shadow;
}
.myCard {
  box-shadow: $shadow;
  padding: 25px;
  border-radius: 5px;
  background-color: white;
  .title {
    color: #707070;
  }
}
.component__icon {
  height: 60px;
  border-radius: 50%;
  width: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg;
  svg {
    fill: $Pcolor;
  }
}
.notification {
  padding: 25px 0;
  cursor: pointer;
  &:hover {
    background-color: $bg;
  }
  .notification__tittle {
    margin: 0;
    text-transform: capitalize;
  }
  .notification__message {
    padding: 10px 0;

    line-height: 24px;
  }
  .notification__date {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 600;
  }
  .notification__link {
    color: $Pcolor;
    font-weight: 500;
    text-decoration: underline;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 600;
  }
}

.full__content {
  margin-left: $sideBarSize2 !important;
}

.bg__P {
  background-color: $Pcolor;
}
.color__P {
  color: $Pcolor;
}
.color__white > span {
  color: white !important;
}
.w-150 {
  width: 150px !important;
}
.w-210 {
  width: 210px !important;
}
.myStciky {
  position: sticky;
  top: 0px;
}

//  for testing only

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.mt-3 {
  margin-top: 10px;
}

.flex {
  display: flex;
  align-items: center;
}

.mr-3 {
  margin-left: 10px;
}

.emty_data {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.comments_container {
  padding: 20px;
}

.error__messages {
  color: red;
  display: flex;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 5px;
}
