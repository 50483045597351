/*---------VArs--------*/
/*---------VArs--------*/
$shadow: 0 2px 8px #f0f1f2;
$sideBarSize: 170px;
$sideBarSize2: 90px;
$headerSize: 50px;
$Scolor: #ff9933;
$bg: #f2f0ff;
$txt: #1c1b1b;
$Pcolor: #134e5e;
$red: #e83c3c;
$green: #22ca14;
$yellow: #fbb707;
