header {
  .img__rounded {
    height: 45px;
    width: 45px;
  }
}

.header {
  height: $headerSize;
  position: fixed;
  left: $sideBarSize;
  // border-bottom-left-radius: 9px;
  // border-bottom-right-radius: 9px;
  z-index: 3;
  width: calc(100% - #{$sideBarSize}) !important;
  top: 0;
  padding: 0 10px;
  // justify-content: space-between;
  box-shadow: $shadow !important;
  background-color: $Pcolor !important;
  color: white !important;
  display: flex;
  align-items: center !important;
  .ico__toggele {
    cursor: pointer;
    margin: 0;
  }
}

.mobileHeader {
  height: $headerSize;
  position: fixed;
  left: $sideBarSize;
  // border-bottom-left-radius: 9px;
  // border-bottom-right-radius: 9px;
  z-index: 3;
  width: 100% !important;
  top: 0;
  padding: 0 10px;
  // justify-content: space-between;
  box-shadow: $shadow !important;
  background-color: $Pcolor !important;
  color: white !important;
  display: flex;
  align-items: center !important;
  .ico__toggele {
    cursor: pointer;
    margin: 0;
  }
}

.min__SideBar {
  width: $sideBarSize2 !important;
  .sidebar__bottom {
    padding: 0;
    svg {
      margin: 0;
      height: 24px !important;
    }
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .sidebar__menu {
    &__element {
      a {
        padding-left: 0 !important;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        flex-direction: column;
        width: $sideBarSize2 !important;
      }
      &__icon {
        margin-right: 0 !important;
        margin-bottom: 5px;
      }
    }
  }
}

.full__header {
  width: calc(100% - #{$sideBarSize2}) !important;
  left: $sideBarSize2;
}

.header__logo {
  height: 50px !important;
  cursor: pointer;
}

.header__dropdown {
  box-shadow: 0px 0px 6px -2px #000000;
  padding: 15px;
  display: block;
  position: absolute;
  top: 75px;
  width: 100%;
  left: 85%;
  color: black;
  background-color: white;
  border: 1px transparent solid;
  border-radius: 4px;
}

.header__dropdown__none {
  display: none;
}

.header__logout {
  margin-top: 15px;
  padding-left: 10px;
}
.header__logout:hover {
  color: red;
}

.header__caret {
  position: absolute;
  top: -10px;
  left: 110px;
  color: white;
}

.header__dropdown__icon {
  padding-right: 10px;
}

@media screen and (max-width: 1000px) {
  .header__dropdown {
    box-shadow: 0px 0px 6px -2px #000000;
    padding: 15px;
    display: block;
    position: absolute;
    top: 75px;
    width: 100%;
    left: 60%;
    color: black;
    background-color: white;
    border: 1px transparent solid;
    border-radius: 4px;
  }

  .header__dropdown__none {
    display: none;
  }

  .header__logout {
    margin-top: 15px;
    padding-left: 10px;
  }
  .header__logout:hover {
    color: red;
  }

  .header__caret {
    position: absolute;
    top: -10px;
    left: 110px;
    color: white;
  }
}

.header__searchInput {
  width: 500px;
  margin-left: 50px;
}

.header_grow {
  flex-grow: 1;
}
